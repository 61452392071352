export const LOGIN_LOADING = "LOGIN_LOADING";
export const GET_RECEIVED_MESSAGES_LOADER = "GET_RECEIVED_MESSAGES_LOADER";
export const RECEIVED_MESSAGES_DATA = "RECEIVED_MESSAGES_DATA";

export const GET_SENT_MESSAGES_LOADER = "GET_SENT_MESSAGES_LOADER";
export const SENT_MESSAGES_DATA = "SENT_MESSAGES_DATA";

export const PROFILE_DATA_LOADER = "PROFILE_DATA_LOADER";
export const PROFILE_DATA = "PROFILE_DATA";

export const FAX_COVER_DATA = "FAX_COVER_DATA";
export const FAX_COVER_DATA_LOADER = "FAX_COVER_DATA_LOADER";

export const FAX_COVER_DELETE = "FAX_COVER_DELETE";
export const FAX_COVER_DELETE_LOADER = "FAX_COVER_DELETE_LOADER";

export const ADD_FAX_COVER_DATA = "ADD_FAX_COVER_DATA";
export const ADD_FAX_COVER_LOADER = "ADD_FAX_COVER_LOADER";

export const UPDATE_FAX_COVER_DATA = "UPDATE_FAX_COVER_DATA";
export const UPDATE_FAX_COVER_LOADER = "UPDATE_FAX_COVER_LOADER";

export const MARK_READ_MESSAGES_LOADER = "MARK_READ_MESSAGES_LOADER";
export const MARK_READ_MESSAGES = "MARK_READ_MESSAGES";

export const MARK_UNREAD_MESSAGES_LOADER = "MARK_UNREAD_MESSAGES_LOADER";
export const MARK_UNREAD_MESSAGES = "MARK_UNREAD_MESSAGES";

export const FAX_HEADER_DATA = "FAX_HEADER_DATA";
export const FAX_HEADER_DATA_LOADER = "FAX_HEADER_DATA_LOADER";

export const FAX_HEADER_DELETE = "FAX_HEADER_DELETE";
export const FAX_HEADER_DELETE_LOADER = "FAX_HEADER_DELETE_LOADER";

export const ADD_FAX_HEADER_LOADER = "ADD_FAX_HEADER_LOADER";

export const UPDATE_FAX_HEADER_DATA = "UPDATE_FAX_HEADER_DATA";
export const UPDATE_FAX_HEADER_LOADER = "UPDATE_FAX_HEADER_LOADER";

export const FAX_BLACKLIST_DATA = "FAX_BLACKLIST_DATA";
export const FAX_BLACKLIST_DATA_LOADER = "FAX_BLACKLIST_DATA_LOADER";

export const FAX_BLACKLIST_DELETE = "FAX_BLACKLIST_DELETE";
export const FAX_BLACKLIST_DELETE_LOADER = "FAX_BLACKLIST_DELETE_LOADER";

export const ADD_FAX_BLACKLIST_DATA = "ADD_FAX_BLACKLIST_DATA";
export const ADD_FAX_BLACKLIST_LOADER = "ADD_FAX_BLACKLIST_LOADER";

export const UPDATE_FAX_BLACKLIST_DATA = "UPDATE_FAX_BLACKLIST_DATA";
export const UPDATE_FAX_BLACKLIST_LOADER = "UPDATE_FAX_BLACKLIST_LOADER";

export const GET_ALERTS_LOADER = "GET_ALERTS_LOADER";
export const GET_ALERTS_DATA = "GET_ALERTS_DATA";

export const ALERT_DELETE_LOADER = "ALERT_DELETE_LOADER";
export const ALERT_DELETE = "ALERT_DELETE";

export const UPDATE_ALERT_LOADER = "UPDATE_ALERT_LOADER";
export const UPDATE_ALERT_DATA = "UPDATE_ALERT_DATA";

export const CREATE_ALERT_LOADER = "CREATE_ALERT_LOADER";
export const CREATE_ALERT_DATA = "CREATE_ALERT_DATA";

export const MARK_ADD_IMPORTANT_LOADER = "MARK_ADD_IMPORTANT_LOADER";
export const MARK_ADD_IMPORTANT_MESSAGES = "MARK_ADD_IMPORTANT_MESSAGES";

export const MARK_REMOVE_IMPORTANT_LOADER = "MARK_REMOVE_IMPORTANT_LOADER";
export const MARK_REMOVE_IMPORTANT_MESSAGES = "MARK_REMOVE_IMPORTANT_MESSAGES";

export const MARK_ADD_STARRED_MESSAGES = "MARK_ADD_STARRED_MESSAGES";

export const MARK_REMOVE_STARRED_MESSAGES = "MARK_REMOVE_STARRED_MESSAGES";

export const MARK_TRASH_LOADER = "MARK_TRASH_LOADER";

export const MARK_READ_SENT_MESSAGES_LOADER = "MARK_READ_SENT_MESSAGES_LOADER";
export const MARK_READ_SENT_MESSAGES = "MARK_READ_SENT_MESSAGES";

export const MARK_UNREAD_SENT_MESSAGES_LOADER =
  "MARK_UNREAD_SENT_MESSAGES_LOADER";
export const MARK_UNREAD_SENT_MESSAGES = "MARK_UNREAD_SENT_MESSAGES";

export const MARK_ADD_IMPORTANT_LOADER_SENT_MESSAGES =
  "MARK_ADD_IMPORTANT_LOADER_SENT_MESSAGES";
export const MARK_ADD_IMPORTANT_SENT_MESSAGES =
  "MARK_ADD_IMPORTANT_SENT_MESSAGES";

export const MARK_REMOVE_IMPORTANT_LOADER_SENT_MESSAGES =
  "MARK_REMOVE_IMPORTANT_LOADER_SENT_MESSAGES";
export const MARK_REMOVE_IMPORTANT_SENT_MESSAGES =
  "MARK_REMOVE_IMPORTANT_SENT_MESSAGES";

export const MARK_ADD_STARRED_SENT_MESSAGES = "MARK_ADD_STARRED_SENT_MESSAGES";

export const MARK_REMOVE_STARRED_SENT_MESSAGES =
  "MARK_REMOVE_STARRED_SENT_MESSAGES";

export const MARK_TRASH_LOADER_SENT_MESSAGE = "MARK_TRASH_LOADER_SENT_MESSAGE";

export const UPDATE_USER_SETTING_LOADER = "UPDATE_USER_SETTING_LOADER";
export const UPDATE_USER_SETTING_DATA = "UPDATE_USER_SETTING_DATA";

export const GET_TRASHED_MESSAGES_LOADER = "GET_TRASHED_MESSAGES_LOADER";
export const TRASHED_MESSAGES_DATA = "TRASHED_MESSAGES_DATA";

export const REMOVE_FROM_TRASH_LOADER = "REMOVE_FROM_TRASH_LOADER";

export const GET_DETAIL_MESSAGES_LOADER = "GET_DETAIL_MESSAGES_LOADER";
export const DETAIL_MESSAGES_DATA = "DETAIL_MESSAGES_DATA";

export const UPDATE_USER_PROFILE_LOADING = "UPDATE_USER_PROFILE_LOADING";
export const UPDATE_USER_PROFILE_DATA = "UPDATE_USER_PROFILE_DATA";

export const UPLOAD_PROFILE_PIC_LOADER = "UPLOAD_PROFILE_PIC_LOADER";

export const COMPOSE_FAX_LOADER = "COMPOSE_FAX_LOADER";
export const COMPOSE_FAX_DATA = "COMPOSE_FAX_DATA";

export const GET_MY_NUMBERS_LOADER = "GET_MY_NUMBERS_LOADER";
export const GET_MY_NUMBERS = "GET_MY_NUMBERS";
export const CANCEL_NUMBER_LOADER = "CANCEL_NUMBER_LOADER";
export const CANCEL_NUMBER_SUCCESS = "CANCEL_NUMBER_SUCCESS";
export const DISABLE_NUMBER_LOADER = "DISABLE_NUMBER_LOADER";
export const DISABLE_NUMBER_SUCCESS = "DISABLE_NUMBER_SUCCESS";
export const ENABLE_NUMBER_LOADER = "ENABLE_NUMBER_LOADER";
export const ENABLE_NUMBER_SUCCESS = "ENABLE_NUMBER_SUCCESS";
export const GET_NUMBER_ROUTE_LOADER = "GET_NUMBER_ROUTE_LOADER";
export const GET_NUMBER_ROUTE = "GET_NUMBER_ROUTE";
export const CREATE_NUMBER_ROUTE_CONFIG_LOADER =
  "CREATE_NUMBER_ROUTE_CONFIG_LOADER";
export const GET_VERIFIED_PHONE_FAX_NUMBERS_LOADER =
  "GET_VERIFIED_PHONE_FAX_NUMBERS_LOADER";
export const GET_VERIFIED_PHONE_FAX_NUMBERS = "GET_VERIFIED_PHONE_FAX_NUMBERS";
export const DELETE_VERIFIED_NUMBER_LOADER = "DELETE_VERIFIED_NUMBER_LOADER";
export const DELETE_VERIFIED_NUMBER = "DELETE_VERIFIED_NUMBER";
export const ADD_VERIFIED_NUMBER_LOADER = "ADD_VERIFIED_NUMBER_LOADER";
export const ADD_VERIFIED_NUMBER = "ADD_VERIFIED_NUMBER";
export const VERIFY_NUMBER_LOADER = "VERIFY_NUMBER_LOADER";
export const VERIFY_NUMBER = "VERIFY_NUMBER";
export const GET_VERIFIED_EMAIL_LOADER = "GET_VERIFIED_EMAIL_LOADER";
export const GET_VERIFIED_EMAIL = "GET_VERIFIED_EMAIL";
export const DELETE_VERIFIED_EMAIL_LOADER = "DELETE_VERIFIED_EMAIL_LOADER";
export const DELETE_VERIFIED_EMAIL = "DELETE_VERIFIED_EMAIL";
export const ADD_VERIFIED_EMAIL_LOADER = "ADD_VERIFIED_EMAIL_LOADER";
export const ADD_VERIFIED_EMAIL = "ADD_VERIFIED_EMAIL";
export const VERIFY_EMAIL_LOADER = "VERIFY_EMAIL_LOADER";
export const VERIFY_EMAIL = "VERIFY_EMAIL";

export const GET_FROM_NUMBERS_LOADER = "GET_FROM_NUMBERS_LOADER";
export const GET_FROM_NUMBERS_DATA = "GET_FROM_NUMBERS_DATA";

export const GET_DASHBORD_DATA_LOADER = "GET_DASHBORD_DATA_LOADER";
export const DASHBOARD_DATA = "DASHBOARD_DATA";
export const GET_FAX_STATS_LOADER = "GET_FAX_STATS_LOADER";
export const FAX_STATS_DATA = "FAX_STATS_DATA";
export const GET_FAX_QUEUES_LOADER = "GET_FAX_QUEUES_LOADER";
export const FAX_QUEUES_DATA = "FAX_QUEUES_DATA";
export const FAX_QUEUES_COUNT_DATA = "FAX_QUEUES_COUNT_DATA";
export const GET_FAX_QUEUES_COUNT_LOADER = "GET_FAX_QUEUES_COUNT_LOADER";
export const SEARCH_FAX_LOADER = "SEARCH_FAX_LOADER";
export const GET_OCR_REQUEST_AVAILABILITY_LOADER =
  "GET_OCR_REQUEST_AVAILABILITY_LOADER";
export const OCR_REQUEST_AVAILABILITY_DATA = "OCR_REQUEST_AVAILABILITY_DATA";
export const GET_OCR_SEARCH_RESULTS_LOADER = "GET_OCR_SEARCH_RESULTS_LOADER";
export const OCR_SEARCH_RESULTS_DATA = "OCR_SEARCH_RESULTS_DATA";
export const GET_CONTACTS_LOADER = "GET_CONTACTS_LOADER";
export const GET_CONTACTS = "GET_CONTACTS";
export const UPDATE_CONTACT_LOADER = "UPDATE_CONTACT_LOADER";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
export const DELETE_CONTACT_LOADER = "DELETE_CONTACT_LOADER";
export const GET_LIST_LOADER = "GET_LIST_LOADER";
export const GET_LIST_SUCCESS = "GET_LIST_SUCCESS";
export const CREATE_CONTACT_SUCCESS = "CREATE_CONTACT_SUCCESS";
export const CREATE_CONTACT_LOADER = "CREATE_CONTACT_LOADER";
export const CREATE_LIST_LOADER = "CREATE_LIST_LOADER";
export const UPDATE_LIST_SUCCESS = "UPDATE_LIST_SUCCESS";
export const UPDATE_LIST_LOADER = "UPDATE_LIST_LOADER";
export const DELETE_LIST_LOADER = "DELETE_LIST_LOADER";
export const DELETE_LIST_SUCCESS = "DELETE_LIST_SUCCESS";
